import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div style={{ textAlign: "center", padding: "5rem 0" }}>
      <h2>Her var det tomt.</h2>
      <p>
        <Link to="/">Gå til forsiden</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
